import {makeAjaxData} from "./tools.js";
import {leafletAutoHeightTitle} from './tools.js';
import {initBreadcrumb} from './breadcrumb.js';
import './amp-breadcrumb';
import './store';
import './selectbox';
import './locality-lazy-loading';
import './category';
import { initHideMessageButtons } from  '../share/message';
import '../share/header';

$(function ()
{
    $('.submit-form').change(function(e) {
        this.form.submit();
    });

    $("#searchBarIcon").click(function() {
        // odeslání formuláře po kliknutí na ikonku lupy
        $('#searchBarSubmit').trigger('click');
    });

    // Po 5s scroll povolíme vždy, protože reklamy mohou být blokovány např. adblockem,
    // tím pádem se to nedostane na onload reklam, kde to odblokováváme.
    setTimeout(function() {
        if (document.querySelectorAll('.no-scroll').length > 0) {
            document.body.classList.remove('no-scroll');
        }
    }, 5000);

    initBreadcrumb();
    loadItems();
    loadStores();
    initCuntomSnippet();
    initHideMessageButtons();
});

function loadItems()
{
    $("#snippet-leaflets-loadButton, #snippet-leafletsArchive-loadButton").on('click', '#loadMoreItems', function ()
    {
        var parentElement = $(this).parent().parent();
        var loading = parentElement.find('#loadMoreItems');
        var spinner = parentElement.find('#spinner');
        
        var ajaxData = makeAjaxData($(this).data());
        
        $.nette.ajax({
            url: ajaxData['link'],
            data: ajaxData['params'],
            start: function ()
            {
                loading.hide();
                spinner.show();
            },
            complete: function ()
            {
                spinner.hide();
                loading.show();
                leafletAutoHeightTitle('.flyerName');
            }
        });
            
        
        return false;
    });
}

function loadStores() {
    $("#snippet-dealerLazyLoading-loadButton").on('click', '#loadMoreStores', function (e) {
        e.preventDefault();

        var parentElement = $(this).parent().parent();
        var loading = parentElement.find('#loadMoreStores');
        var spinner = parentElement.find('#spinner');
        
        var ajaxData = makeAjaxData($(this).data());

        $.nette.ajax({
            url: ajaxData['link'],
            data: ajaxData['params'],
            start: function () {
                loading.hide();
                spinner.show();
            },
            complete: function () {
                spinner.hide();
                loading.show();
            }
        });

        return false;
    });
}

function initCuntomSnippet()
{
    // abychom zabránili duplikaci listenerů, když je na stránce více prvků s custom-snippet classou, nejdřív využijeme off
    $('.custom-snippet').off('click').on('click', function(e) {
        e.preventDefault();

        let parentElement = $(this).parent();

        var loading = parentElement.find('.button');
        var spinner = parentElement.find('.spinner');

        let ajaxData = makeAjaxData($(this).data());

        $.nette.ajax({
            url: ajaxData['link'],
            data: ajaxData['params'],
            start: function () {
                loading.hide();
                spinner.show();
            },
            complete: function () {
                spinner.hide();
                loading.show();

                // reinicializujeme handler
                initCuntomSnippet();
            }
        });
    });
}
