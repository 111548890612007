import {makeAjaxData} from "./tools.js";

$(function ()
{
    loadItemsCategory();
});

function loadItemsCategory()
{
    $('.category-goods').on('click', '.arrow',function ()
    {
        let loading = $('.category-goods .arrow');
        let spinner = $('.category-goods .spinner');
        
        var ajaxData = makeAjaxData($(this).data());
        
        $.nette.ajax({
            url: ajaxData['link'],
            data: ajaxData['params'],
            start: function ()
            {
                loading.hide();
                spinner.show();
            },
            complete: function ()
            {
                spinner.hide();
                loading.show();
            }
        });
        
        return false;
    });
}