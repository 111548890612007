import {makeAjaxData} from "./tools.js";

$(function () {
    loadLocalities();
});

function loadLocalities() {
    $("#snippet-localityLazyLoading-loadButton").on('click', '#loadMoreLocalities', function (e) {
        e.preventDefault();

        var parentElement = $(this).parent().parent();
        var loading = parentElement.find('#loadMoreLocalities');
        var spinner = parentElement.find('#spinner');

        var ajaxData = makeAjaxData($(this).data());

        $.nette.ajax({
            url: ajaxData['link'],
            data: ajaxData['params'],
            start: function () {
                loading.hide();
                spinner.show();
            },
            complete: function () {
                spinner.hide();
                loading.show();
            }
        });

        return false;
    });
}