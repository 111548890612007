$(function () {
    bannerStickyDesktop();
});

/**
 * Pro banner STORE-LEAFLET_DESKTOP_RIGHT a STORE-LEAFLET_DESKTOP_LEFT vypočítá height, aby mohl fungovat jako sticky.
 */
function bannerStickyDesktop() {
    let containerHeight = $('.container').height();
    let headerHeight = ($('.container .top-banner').height() ?? 0) + ($('.container .header').height() ?? 0) + ($('.container .searchbar-wrapper').height() ?? 0) + ($('.container .navigation').height() ?? 0);

    // pro obal banneru nastavíme výšku
    $('.banner-sticky-height').height(containerHeight - headerHeight);
}