export function makeAjaxData(dataParams)
{
    // získáme jméno komponenty a link
    var controlName = dataParams['controlName'];
    var controlLink = dataParams['link'];

    // vyhodíme vyhrazená data link a controlName
    delete dataParams['link'];
    delete dataParams['controlName'];

    // založím objekt s parametry
    var params = {};

    // vyrobíme params, které má stejné hodnoty jako dataParams, ale klíče
    // obsahují prefix názvu komponenty
    for (let key in dataParams)
    {
        params[controlName + '-' + key] = dataParams[key];
    }

    return {
        link: controlLink,
        params: params
    };
}

export function leafletAutoHeightTitle(className) {

    var flyerNameMax = 0;
    var flyerTop = 0;
    var flyers = $(className);
    flyers.each(function(){
        if ($(this).offset().top != flyerTop)
        {
            flyers.each(function() {
                if ($(this).offset().top == flyerTop)
                    $(this).height(flyerNameMax);
            });
            flyerTop = $(this).offset().top;
            flyerNameMax = 0;
        }
        if ($(this).height() > flyerNameMax)
            flyerNameMax = $(this).height();
    });
    flyers.each(function() {
        if ($(this).offset().top == flyerTop)
            $(this).height(flyerNameMax);
    });
}