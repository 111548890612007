
export function initBreadcrumb()
{
    // rozbalení drobečkové navigace
    let items = Array.from(document.getElementsByClassName('item-click'));

    items.forEach(function (item) {

        item.addEventListener('click', function(e) {

            e.preventDefault();

            let navigationBlock = document.getElementsByClassName('navigation');

            if(navigationBlock.length !== 1)
            {
                return;
            }
            navigationBlock = navigationBlock[0];
            items = Array.from(navigationBlock.getElementsByClassName('hidden-xs'));

            items.forEach(function (element) {
                element.classList.remove('hidden-xs');
            });

            items = Array.from(navigationBlock.getElementsByClassName('visible-xs-inline'));

            items.forEach(function (element) {
                element.classList.remove('visible-xs-inline');
                element.classList.add('hidden-xs');
            });
        });
    })
}