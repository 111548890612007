$(function ()
{
    $('.slicknav-btn').click(function(){
        
        if ($('.slicknav_nav').is(':visible')) {
            $('.slicknav_nav').slideUp(500);
        }
        else {
            $('.slicknav_nav').slideDown(500);
        }
        
    });

    initMobileSearch();
});

function initMobileSearch() {
    $('.mobile-menu .search-icon').click(function () {
        const searchForm = $('.menu-wrapper .search-input-wrapper');

        if (searchForm.is(":hidden")) {
            searchForm.fadeIn();
        } else {
            searchForm.fadeOut();
        }

    });

    // pokud klickneme na close, zavřeme
    $(".search-input-icon-wrapper .close-icon").click(function (e) {
        e.preventDefault();
        const searchForm = $('.menu-wrapper .search-input-wrapper');
        searchForm.fadeOut();
    });

    $(".search-input-icon-wrapper .icon").click(function () {
        // odeslání formuláře po kliknutí na ikonku lupy
        $('#searchBarSubmitMobile').trigger('click');
    });
}