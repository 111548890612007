const MESSAGE_COMPONENT_CLASSNAME = 'message';

export const initHideMessageButtons = () => {
    const messageElementsList = document.getElementsByClassName(MESSAGE_COMPONENT_CLASSNAME);

    if (!messageElementsList) {
        return;
    }

    for (let messageElement of messageElementsList) {
        const messageHideButtonElement = messageElement.querySelector('button');

        if (messageHideButtonElement) {
            messageHideButtonElement.onclick = () => fadeOutElement(messageElement);
        }
    }
}

const fadeOutElement = (element) => {
    if (!element) {
        return;
    }

    element.style.opacity = '0';
    setTimeout(() => {
        element.remove();
    }, 1000);
}